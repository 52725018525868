// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SubjectPromoCodeSubjectPlatformEnum } from './SubjectPromoCodeSubjectPlatformEnum';
import {
    SubjectPromoCodeSubjectPlatformEnumFromJSON,
    SubjectPromoCodeSubjectPlatformEnumFromJSONTyped,
    SubjectPromoCodeSubjectPlatformEnumToJSON,
} from './SubjectPromoCodeSubjectPlatformEnum';

/**
 * 
 * @export
 * @interface SubjectPromoCodeSubject
 */
export interface SubjectPromoCodeSubject {
    /**
     * 
     * @type {string}
     * @memberof SubjectPromoCodeSubject
     */
    img: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectPromoCodeSubject
     */
    name: string;
    /**
     * 
     * @type {SubjectPromoCodeSubjectPlatformEnum}
     * @memberof SubjectPromoCodeSubject
     */
    readonly platform: SubjectPromoCodeSubjectPlatformEnum;
}

/**
 * Check if a given object implements the SubjectPromoCodeSubject interface.
 */
export function instanceOfSubjectPromoCodeSubject(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "img" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "platform" in value;

    return isInstance;
}

export function SubjectPromoCodeSubjectFromJSON(json: any): SubjectPromoCodeSubject {
    return SubjectPromoCodeSubjectFromJSONTyped(json, false);
}

export function SubjectPromoCodeSubjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectPromoCodeSubject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'img': json['img'],
        'name': json['name'],
        'platform': SubjectPromoCodeSubjectPlatformEnumFromJSON(json['platform']),
    };
}

export function SubjectPromoCodeSubjectToJSON(value?: SubjectPromoCodeSubject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'img': value.img,
        'name': value.name,
    };
}

