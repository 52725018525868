// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `pubg` - ПАБГ
 * * `play_market` - Плей маркет
 * * `app_store` - Апп стор
 * @export
 */
export const CodeTypeEnum = {
    Pubg: 'pubg',
    PlayMarket: 'play_market',
    AppStore: 'app_store'
} as const;
export type CodeTypeEnum = typeof CodeTypeEnum[keyof typeof CodeTypeEnum];


export function CodeTypeEnumFromJSON(json: any): CodeTypeEnum {
    return CodeTypeEnumFromJSONTyped(json, false);
}

export function CodeTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodeTypeEnum {
    return json as CodeTypeEnum;
}

export function CodeTypeEnumToJSON(value?: CodeTypeEnum | null): any {
    return value as any;
}

