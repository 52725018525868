// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ru` - Russian
 * * `en` - English
 * * `uz` - Uzbekistan
 * * `es` - Spanish
 * @export
 */
export const LanguageB27Enum = {
    Ru: 'ru',
    En: 'en',
    Uz: 'uz',
    Es: 'es'
} as const;
export type LanguageB27Enum = typeof LanguageB27Enum[keyof typeof LanguageB27Enum];


export function LanguageB27EnumFromJSON(json: any): LanguageB27Enum {
    return LanguageB27EnumFromJSONTyped(json, false);
}

export function LanguageB27EnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguageB27Enum {
    return json as LanguageB27Enum;
}

export function LanguageB27EnumToJSON(value?: LanguageB27Enum | null): any {
    return value as any;
}

