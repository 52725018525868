// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    BattlePassPromoCodeTyped,
    instanceOfBattlePassPromoCodeTyped,
    BattlePassPromoCodeTypedFromJSON,
    BattlePassPromoCodeTypedFromJSONTyped,
    BattlePassPromoCodeTypedToJSON,
} from './BattlePassPromoCodeTyped';
import {
    BossBattleAttackPointsPromoCodeTyped,
    instanceOfBossBattleAttackPointsPromoCodeTyped,
    BossBattleAttackPointsPromoCodeTypedFromJSON,
    BossBattleAttackPointsPromoCodeTypedFromJSONTyped,
    BossBattleAttackPointsPromoCodeTypedToJSON,
} from './BossBattleAttackPointsPromoCodeTyped';
import {
    CasePromoCodeTyped,
    instanceOfCasePromoCodeTyped,
    CasePromoCodeTypedFromJSON,
    CasePromoCodeTypedFromJSONTyped,
    CasePromoCodeTypedToJSON,
} from './CasePromoCodeTyped';
import {
    EmptyPromoCodeTyped,
    instanceOfEmptyPromoCodeTyped,
    EmptyPromoCodeTypedFromJSON,
    EmptyPromoCodeTypedFromJSONTyped,
    EmptyPromoCodeTypedToJSON,
} from './EmptyPromoCodeTyped';
import {
    ExchangerPromoCodeTyped,
    instanceOfExchangerPromoCodeTyped,
    ExchangerPromoCodeTypedFromJSON,
    ExchangerPromoCodeTypedFromJSONTyped,
    ExchangerPromoCodeTypedToJSON,
} from './ExchangerPromoCodeTyped';
import {
    FixGoldPromoCodeTyped,
    instanceOfFixGoldPromoCodeTyped,
    FixGoldPromoCodeTypedFromJSON,
    FixGoldPromoCodeTypedFromJSONTyped,
    FixGoldPromoCodeTypedToJSON,
} from './FixGoldPromoCodeTyped';
import {
    PercentOnBuyPromoCodeTyped,
    instanceOfPercentOnBuyPromoCodeTyped,
    PercentOnBuyPromoCodeTypedFromJSON,
    PercentOnBuyPromoCodeTypedFromJSONTyped,
    PercentOnBuyPromoCodeTypedToJSON,
} from './PercentOnBuyPromoCodeTyped';
import {
    SubjectPromoCodeTyped,
    instanceOfSubjectPromoCodeTyped,
    SubjectPromoCodeTypedFromJSON,
    SubjectPromoCodeTypedFromJSONTyped,
    SubjectPromoCodeTypedToJSON,
} from './SubjectPromoCodeTyped';

/**
 * @type PromoCode
 * 
 * @export
 */
export type PromoCode = { resourcetype: 'BattlePassPromoCode' } & BattlePassPromoCodeTyped | { resourcetype: 'BossBattleAttackPointsPromoCode' } & BossBattleAttackPointsPromoCodeTyped | { resourcetype: 'CasePromoCode' } & CasePromoCodeTyped | { resourcetype: 'EmptyPromoCode' } & EmptyPromoCodeTyped | { resourcetype: 'ExchangerPromoCode' } & ExchangerPromoCodeTyped | { resourcetype: 'FixGoldPromoCode' } & FixGoldPromoCodeTyped | { resourcetype: 'PercentOnBuyPromoCode' } & PercentOnBuyPromoCodeTyped | { resourcetype: 'SubjectPromoCode' } & SubjectPromoCodeTyped;

export function PromoCodeFromJSON(json: any): PromoCode {
    return PromoCodeFromJSONTyped(json, false);
}

export function PromoCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromoCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['resourcetype']) {
        case 'BattlePassPromoCode':
            return {...BattlePassPromoCodeTypedFromJSONTyped(json, true), resourcetype: 'BattlePassPromoCode'};
        case 'BossBattleAttackPointsPromoCode':
            return {...BossBattleAttackPointsPromoCodeTypedFromJSONTyped(json, true), resourcetype: 'BossBattleAttackPointsPromoCode'};
        case 'CasePromoCode':
            return {...CasePromoCodeTypedFromJSONTyped(json, true), resourcetype: 'CasePromoCode'};
        case 'EmptyPromoCode':
            return {...EmptyPromoCodeTypedFromJSONTyped(json, true), resourcetype: 'EmptyPromoCode'};
        case 'ExchangerPromoCode':
            return {...ExchangerPromoCodeTypedFromJSONTyped(json, true), resourcetype: 'ExchangerPromoCode'};
        case 'FixGoldPromoCode':
            return {...FixGoldPromoCodeTypedFromJSONTyped(json, true), resourcetype: 'FixGoldPromoCode'};
        case 'PercentOnBuyPromoCode':
            return {...PercentOnBuyPromoCodeTypedFromJSONTyped(json, true), resourcetype: 'PercentOnBuyPromoCode'};
        case 'SubjectPromoCode':
            return {...SubjectPromoCodeTypedFromJSONTyped(json, true), resourcetype: 'SubjectPromoCode'};
        default:
            throw new Error(`No variant of PromoCode exists with 'resourcetype=${json['resourcetype']}'`);
    }
}

export function PromoCodeToJSON(value?: PromoCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['resourcetype']) {
        case 'BattlePassPromoCode':
            return BattlePassPromoCodeTypedToJSON(value);
        case 'BossBattleAttackPointsPromoCode':
            return BossBattleAttackPointsPromoCodeTypedToJSON(value);
        case 'CasePromoCode':
            return CasePromoCodeTypedToJSON(value);
        case 'EmptyPromoCode':
            return EmptyPromoCodeTypedToJSON(value);
        case 'ExchangerPromoCode':
            return ExchangerPromoCodeTypedToJSON(value);
        case 'FixGoldPromoCode':
            return FixGoldPromoCodeTypedToJSON(value);
        case 'PercentOnBuyPromoCode':
            return PercentOnBuyPromoCodeTypedToJSON(value);
        case 'SubjectPromoCode':
            return SubjectPromoCodeTypedToJSON(value);
        default:
            throw new Error(`No variant of PromoCode exists with 'resourcetype=${value['resourcetype']}'`);
    }

}

