// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CasePromoCodeCase } from './CasePromoCodeCase';
import {
    CasePromoCodeCaseFromJSON,
    CasePromoCodeCaseFromJSONTyped,
    CasePromoCodeCaseToJSON,
} from './CasePromoCodeCase';

/**
 * 
 * @export
 * @interface CasePromoCodeTyped
 */
export interface CasePromoCodeTyped {
    /**
     * 
     * @type {string}
     * @memberof CasePromoCodeTyped
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CasePromoCodeTyped
     */
    platform: string;
    /**
     * 
     * @type {CasePromoCodeCase}
     * @memberof CasePromoCodeTyped
     */
    readonly _case: CasePromoCodeCase;
    /**
     * 
     * @type {string}
     * @memberof CasePromoCodeTyped
     */
    resourcetype: string;
}

/**
 * Check if a given object implements the CasePromoCodeTyped interface.
 */
export function instanceOfCasePromoCodeTyped(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "platform" in value;
    isInstance = isInstance && "_case" in value;
    isInstance = isInstance && "resourcetype" in value;

    return isInstance;
}

export function CasePromoCodeTypedFromJSON(json: any): CasePromoCodeTyped {
    return CasePromoCodeTypedFromJSONTyped(json, false);
}

export function CasePromoCodeTypedFromJSONTyped(json: any, ignoreDiscriminator: boolean): CasePromoCodeTyped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
        'platform': json['platform'],
        '_case': CasePromoCodeCaseFromJSON(json['case']),
        'resourcetype': json['resourcetype'],
    };
}

export function CasePromoCodeTypedToJSON(value?: CasePromoCodeTyped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'platform': value.platform,
        'resourcetype': value.resourcetype,
    };
}

