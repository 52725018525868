// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeTypeEnum } from './CodeTypeEnum';
import {
    CodeTypeEnumFromJSON,
    CodeTypeEnumFromJSONTyped,
    CodeTypeEnumToJSON,
} from './CodeTypeEnum';

/**
 * 
 * @export
 * @interface MakePUBGWithdrawalRequest
 */
export interface MakePUBGWithdrawalRequest {
    /**
     * 
     * @type {number}
     * @memberof MakePUBGWithdrawalRequest
     */
    amountBullcoins: number;
    /**
     * 
     * @type {string}
     * @memberof MakePUBGWithdrawalRequest
     */
    pubgUid?: string;
    /**
     * 
     * @type {CodeTypeEnum}
     * @memberof MakePUBGWithdrawalRequest
     */
    codeType?: CodeTypeEnum;
}

/**
 * Check if a given object implements the MakePUBGWithdrawalRequest interface.
 */
export function instanceOfMakePUBGWithdrawalRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amountBullcoins" in value;

    return isInstance;
}

export function MakePUBGWithdrawalRequestFromJSON(json: any): MakePUBGWithdrawalRequest {
    return MakePUBGWithdrawalRequestFromJSONTyped(json, false);
}

export function MakePUBGWithdrawalRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MakePUBGWithdrawalRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amountBullcoins': json['amount_bullcoins'],
        'pubgUid': !exists(json, 'pubg_uid') ? undefined : json['pubg_uid'],
        'codeType': !exists(json, 'code_type') ? undefined : CodeTypeEnumFromJSON(json['code_type']),
    };
}

export function MakePUBGWithdrawalRequestToJSON(value?: MakePUBGWithdrawalRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_bullcoins': value.amountBullcoins,
        'pubg_uid': value.pubgUid,
        'code_type': CodeTypeEnumToJSON(value.codeType),
    };
}

