// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CodeTypeEnum } from './CodeTypeEnum';
import {
    CodeTypeEnumFromJSON,
    CodeTypeEnumFromJSONTyped,
    CodeTypeEnumToJSON,
} from './CodeTypeEnum';
import type { PUBGCode } from './PUBGCode';
import {
    PUBGCodeFromJSON,
    PUBGCodeFromJSONTyped,
    PUBGCodeToJSON,
} from './PUBGCode';
import type { WithdrawalStatusEnum } from './WithdrawalStatusEnum';
import {
    WithdrawalStatusEnumFromJSON,
    WithdrawalStatusEnumFromJSONTyped,
    WithdrawalStatusEnumToJSON,
} from './WithdrawalStatusEnum';

/**
 * 
 * @export
 * @interface PUBGWithdrawalTyped
 */
export interface PUBGWithdrawalTyped {
    /**
     * 
     * @type {number}
     * @memberof PUBGWithdrawalTyped
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof PUBGWithdrawalTyped
     */
    amountBullcoins: number;
    /**
     * 
     * @type {number}
     * @memberof PUBGWithdrawalTyped
     */
    amountItems: number;
    /**
     * 
     * @type {WithdrawalStatusEnum}
     * @memberof PUBGWithdrawalTyped
     */
    status: WithdrawalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PUBGWithdrawalTyped
     */
    pubgUid: string;
    /**
     * 
     * @type {Date}
     * @memberof PUBGWithdrawalTyped
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof PUBGWithdrawalTyped
     */
    cancelComment: string | null;
    /**
     * 
     * @type {Array<PUBGCode>}
     * @memberof PUBGWithdrawalTyped
     */
    readonly codes: Array<PUBGCode>;
    /**
     * 
     * @type {CodeTypeEnum}
     * @memberof PUBGWithdrawalTyped
     */
    codeType: CodeTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof PUBGWithdrawalTyped
     */
    resourcetype: string;
}

/**
 * Check if a given object implements the PUBGWithdrawalTyped interface.
 */
export function instanceOfPUBGWithdrawalTyped(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "amountBullcoins" in value;
    isInstance = isInstance && "amountItems" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "pubgUid" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "cancelComment" in value;
    isInstance = isInstance && "codes" in value;
    isInstance = isInstance && "codeType" in value;
    isInstance = isInstance && "resourcetype" in value;

    return isInstance;
}

export function PUBGWithdrawalTypedFromJSON(json: any): PUBGWithdrawalTyped {
    return PUBGWithdrawalTypedFromJSONTyped(json, false);
}

export function PUBGWithdrawalTypedFromJSONTyped(json: any, ignoreDiscriminator: boolean): PUBGWithdrawalTyped {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'amountBullcoins': json['amount_bullcoins'],
        'amountItems': json['amount_items'],
        'status': WithdrawalStatusEnumFromJSON(json['status']),
        'pubgUid': json['pubg_uid'],
        'created': (new Date(json['created'])),
        'cancelComment': json['cancel_comment'],
        'codes': ((json['codes'] as Array<any>).map(PUBGCodeFromJSON)),
        'codeType': CodeTypeEnumFromJSON(json['code_type']),
        'resourcetype': json['resourcetype'],
    };
}

export function PUBGWithdrawalTypedToJSON(value?: PUBGWithdrawalTyped | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount_bullcoins': value.amountBullcoins,
        'amount_items': value.amountItems,
        'status': WithdrawalStatusEnumToJSON(value.status),
        'pubg_uid': value.pubgUid,
        'created': (value.created.toISOString()),
        'cancel_comment': value.cancelComment,
        'code_type': CodeTypeEnumToJSON(value.codeType),
        'resourcetype': value.resourcetype,
    };
}

