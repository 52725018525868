// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `profiles` - Profiles
 * * `user_ban` - User Ban
 * * `message_edit` - Message Edit
 * * `users` - Users
 * * `users:read` - Users Read
 * * `users:read:partners` - Users Read Partners
 * * `users:restrict` - Users Restrict
 * * `users:logs` - Users Logs
 * * `users:edit` - Users Edit
 * * `users:payments` - Users Payments
 * * `users:withdrawals` - Users Withdrawals
 * * `users:inventory:read` - Users Inventory Read
 * * `users:inventory` - Users Inventory
 * * `users:referral` - Users Referral
 * * `users:boss_battle` - Users Boss Battle
 * * `fraud` - Fraud
 * * `payment_methods` - Payment Methods
 * * `payment_methods:read` - Payment Methods Read
 * * `payments` - Payments
 * * `payments:read` - Payments Read
 * * `payments:accept` - Payments Accept
 * * `payments:reject` - Payments Reject
 * * `promocodes` - Promocodes
 * * `promocodes:edit` - Promocodes Edit
 * * `promocodes:add` - Promocodes Add
 * * `promocodes:delete` - Promocodes Delete
 * * `promocodes:read` - Promocodes Read
 * * `withdrawals` - Withdrawals
 * * `withdrawals:read` - Withdrawals Read
 * * `withdrawals:edit` - Withdrawals Edit
 * * `withdrawals:settings` - Withdrawals Settings
 * * `games_settings` - Games Settings
 * * `subjects` - Subjects
 * * `subjects:read` - Subjects Read
 * * `notifications` - Notifications
 * * `chat` - Chat
 * * `debug` - Debug
 * * `super_user` - Super User
 * * `cases` - Cases
 * * `marketing_bot` - Marketing Bot
 * @export
 */
export const PermissionsEnum = {
    Profiles: 'profiles',
    UserBan: 'user_ban',
    MessageEdit: 'message_edit',
    Users: 'users',
    Usersread: 'users:read',
    Usersreadpartners: 'users:read:partners',
    Usersrestrict: 'users:restrict',
    Userslogs: 'users:logs',
    Usersedit: 'users:edit',
    Userspayments: 'users:payments',
    Userswithdrawals: 'users:withdrawals',
    Usersinventoryread: 'users:inventory:read',
    Usersinventory: 'users:inventory',
    Usersreferral: 'users:referral',
    UsersbossBattle: 'users:boss_battle',
    Fraud: 'fraud',
    PaymentMethods: 'payment_methods',
    PaymentMethodsread: 'payment_methods:read',
    Payments: 'payments',
    Paymentsread: 'payments:read',
    Paymentsaccept: 'payments:accept',
    Paymentsreject: 'payments:reject',
    Promocodes: 'promocodes',
    Promocodesedit: 'promocodes:edit',
    Promocodesadd: 'promocodes:add',
    Promocodesdelete: 'promocodes:delete',
    Promocodesread: 'promocodes:read',
    Withdrawals: 'withdrawals',
    Withdrawalsread: 'withdrawals:read',
    Withdrawalsedit: 'withdrawals:edit',
    Withdrawalssettings: 'withdrawals:settings',
    GamesSettings: 'games_settings',
    Subjects: 'subjects',
    Subjectsread: 'subjects:read',
    Notifications: 'notifications',
    Chat: 'chat',
    Debug: 'debug',
    SuperUser: 'super_user',
    Cases: 'cases',
    MarketingBot: 'marketing_bot'
} as const;
export type PermissionsEnum = typeof PermissionsEnum[keyof typeof PermissionsEnum];


export function PermissionsEnumFromJSON(json: any): PermissionsEnum {
    return PermissionsEnumFromJSONTyped(json, false);
}

export function PermissionsEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionsEnum {
    return json as PermissionsEnum;
}

export function PermissionsEnumToJSON(value?: PermissionsEnum | null): any {
    return value as any;
}

