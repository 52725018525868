// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SubjectPromoCodeSubjectPlatformEnum = {
    Standoff: 'standoff',
    Genshin: 'genshin',
    Pubg: 'pubg',
    Roblox: 'roblox',
    Cs2: 'cs2'
} as const;
export type SubjectPromoCodeSubjectPlatformEnum = typeof SubjectPromoCodeSubjectPlatformEnum[keyof typeof SubjectPromoCodeSubjectPlatformEnum];


export function SubjectPromoCodeSubjectPlatformEnumFromJSON(json: any): SubjectPromoCodeSubjectPlatformEnum {
    return SubjectPromoCodeSubjectPlatformEnumFromJSONTyped(json, false);
}

export function SubjectPromoCodeSubjectPlatformEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectPromoCodeSubjectPlatformEnum {
    return json as SubjectPromoCodeSubjectPlatformEnum;
}

export function SubjectPromoCodeSubjectPlatformEnumToJSON(value?: SubjectPromoCodeSubjectPlatformEnum | null): any {
    return value as any;
}

