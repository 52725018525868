// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ArtifactInventory,
  BattlePassInfo,
  BossBattleAttackAction,
  BossBattleAttackRequest,
  BossBattleEvent,
  BossBattleProfile,
  BossBattleTopResponse,
  DecorationRewardInfo,
  ExchangerProfile,
  ExchangerRewardRange,
  ExchangerSettings,
  ExchangerTopUser,
  MomentRewardInfo,
  RewardInfo,
  RewardRange,
  SubjectInventory,
  SubjectInventoryRequest,
  SubjectWithChanceRewardInfo,
  TakeRewardRequest,
} from '../models/index';
import {
    ArtifactInventoryFromJSON,
    ArtifactInventoryToJSON,
    BattlePassInfoFromJSON,
    BattlePassInfoToJSON,
    BossBattleAttackActionFromJSON,
    BossBattleAttackActionToJSON,
    BossBattleAttackRequestFromJSON,
    BossBattleAttackRequestToJSON,
    BossBattleEventFromJSON,
    BossBattleEventToJSON,
    BossBattleProfileFromJSON,
    BossBattleProfileToJSON,
    BossBattleTopResponseFromJSON,
    BossBattleTopResponseToJSON,
    DecorationRewardInfoFromJSON,
    DecorationRewardInfoToJSON,
    ExchangerProfileFromJSON,
    ExchangerProfileToJSON,
    ExchangerRewardRangeFromJSON,
    ExchangerRewardRangeToJSON,
    ExchangerSettingsFromJSON,
    ExchangerSettingsToJSON,
    ExchangerTopUserFromJSON,
    ExchangerTopUserToJSON,
    MomentRewardInfoFromJSON,
    MomentRewardInfoToJSON,
    RewardInfoFromJSON,
    RewardInfoToJSON,
    RewardRangeFromJSON,
    RewardRangeToJSON,
    SubjectInventoryFromJSON,
    SubjectInventoryToJSON,
    SubjectInventoryRequestFromJSON,
    SubjectInventoryRequestToJSON,
    SubjectWithChanceRewardInfoFromJSON,
    SubjectWithChanceRewardInfoToJSON,
    TakeRewardRequestFromJSON,
    TakeRewardRequestToJSON,
} from '../models/index';

export interface EventsApiBattlePassDecorationsRewardInfoRetrieveRequest {
    levelId: number;
    slug: string;
}

export interface EventsApiBattlePassMomentRewardInfoRetrieveRequest {
    levelId: number;
    slug: string;
}

export interface EventsApiBattlePassProgressRetrieveRequest {
    slug: string;
}

export interface EventsApiBattlePassSecretSubjectsRetrieveRequest {
    slug: string;
}

export interface EventsApiBattlePassSubjectChancesRewardInfoRetrieveRequest {
    levelId: number;
    slug: string;
}

export interface EventsApiBattlePassTakeRewardCreateRequest {
    slug: string;
    takeRewardRequest: TakeRewardRequest;
}

export interface EventsApiBattlePassUserRewardInfoRetrieveRequest {
    levelId: number;
    slug: string;
}

export interface EventsApiBossBattleAttackActionsListRequest {
    slug: string;
}

export interface EventsApiBossBattleAttackCreateRequest {
    slug: string;
    bossBattleAttackRequest: BossBattleAttackRequest;
}

export interface EventsApiBossBattleProfileRetrieveRequest {
    slug: string;
}

export interface EventsApiBossBattleRetrieveRequest {
    slug: string;
}

export interface EventsApiBossBattleRewardsListRequest {
    slug: string;
}

export interface EventsApiBossBattleTopRetrieveRequest {
    slug: string;
    limit?: number;
    offset?: number;
}

export interface EventsApiExchangerExchangeCreateRequest {
    slug: string;
    subjectInventoryRequest?: SubjectInventoryRequest;
}

export interface EventsApiExchangerProfileRetrieveRequest {
    slug: string;
}

export interface EventsApiExchangerRetrieveRequest {
    slug: string;
}

export interface EventsApiExchangerTopListRequest {
    slug: string;
    limit?: number;
    offset?: number;
}

export interface EventsApiExchangerTopRewardsListRequest {
    slug: string;
}

export interface EventsApiExchangerTopUserRetrieveRequest {
    slug: string;
}

/**
 * 
 */
export class EventsApi extends runtime.BaseAPI {

    /**
     */
    async artifactsMyListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ArtifactInventory>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/artifacts/my/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ArtifactInventoryFromJSON));
    }

    /**
     */
    async artifactsMyList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ArtifactInventory>> {
        const response = await this.artifactsMyListRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async battlePassDecorationsRewardInfoRetrieveRaw(requestParameters: EventsApiBattlePassDecorationsRewardInfoRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DecorationRewardInfo>> {
        if (requestParameters['levelId'] == null) {
            throw new runtime.RequiredError(
                'levelId',
                'Required parameter "levelId" was null or undefined when calling battlePassDecorationsRewardInfoRetrieve().'
            );
        }

        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling battlePassDecorationsRewardInfoRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/battle-pass/decorations-reward-info/{level_id}/`.replace(`{${"level_id"}}`, encodeURIComponent(String(requestParameters['levelId']))).replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DecorationRewardInfoFromJSON(jsonValue));
    }

    /**
     */
    async battlePassDecorationsRewardInfoRetrieve(requestParameters: EventsApiBattlePassDecorationsRewardInfoRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DecorationRewardInfo> {
        const response = await this.battlePassDecorationsRewardInfoRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async battlePassMomentRewardInfoRetrieveRaw(requestParameters: EventsApiBattlePassMomentRewardInfoRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MomentRewardInfo>> {
        if (requestParameters['levelId'] == null) {
            throw new runtime.RequiredError(
                'levelId',
                'Required parameter "levelId" was null or undefined when calling battlePassMomentRewardInfoRetrieve().'
            );
        }

        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling battlePassMomentRewardInfoRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/battle-pass/moment-reward-info/{level_id}/`.replace(`{${"level_id"}}`, encodeURIComponent(String(requestParameters['levelId']))).replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MomentRewardInfoFromJSON(jsonValue));
    }

    /**
     */
    async battlePassMomentRewardInfoRetrieve(requestParameters: EventsApiBattlePassMomentRewardInfoRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MomentRewardInfo> {
        const response = await this.battlePassMomentRewardInfoRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async battlePassProgressRetrieveRaw(requestParameters: EventsApiBattlePassProgressRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BattlePassInfo>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling battlePassProgressRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/battle-pass/progress/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BattlePassInfoFromJSON(jsonValue));
    }

    /**
     */
    async battlePassProgressRetrieve(requestParameters: EventsApiBattlePassProgressRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BattlePassInfo> {
        const response = await this.battlePassProgressRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * @deprecated
     */
    async battlePassSecretSubjectsRetrieveRaw(requestParameters: EventsApiBattlePassSecretSubjectsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArtifactInventory>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling battlePassSecretSubjectsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/battle-pass/secret-subjects/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtifactInventoryFromJSON(jsonValue));
    }

    /**
     * @deprecated
     */
    async battlePassSecretSubjectsRetrieve(requestParameters: EventsApiBattlePassSecretSubjectsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArtifactInventory> {
        const response = await this.battlePassSecretSubjectsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async battlePassSubjectChancesRewardInfoRetrieveRaw(requestParameters: EventsApiBattlePassSubjectChancesRewardInfoRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubjectWithChanceRewardInfo>> {
        if (requestParameters['levelId'] == null) {
            throw new runtime.RequiredError(
                'levelId',
                'Required parameter "levelId" was null or undefined when calling battlePassSubjectChancesRewardInfoRetrieve().'
            );
        }

        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling battlePassSubjectChancesRewardInfoRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/battle-pass/subject-chances-reward-info/{level_id}/`.replace(`{${"level_id"}}`, encodeURIComponent(String(requestParameters['levelId']))).replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectWithChanceRewardInfoFromJSON(jsonValue));
    }

    /**
     */
    async battlePassSubjectChancesRewardInfoRetrieve(requestParameters: EventsApiBattlePassSubjectChancesRewardInfoRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubjectWithChanceRewardInfo> {
        const response = await this.battlePassSubjectChancesRewardInfoRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async battlePassTakeRewardCreateRaw(requestParameters: EventsApiBattlePassTakeRewardCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RewardInfo>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling battlePassTakeRewardCreate().'
            );
        }

        if (requestParameters['takeRewardRequest'] == null) {
            throw new runtime.RequiredError(
                'takeRewardRequest',
                'Required parameter "takeRewardRequest" was null or undefined when calling battlePassTakeRewardCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/battle-pass/take-reward/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TakeRewardRequestToJSON(requestParameters['takeRewardRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RewardInfoFromJSON(jsonValue));
    }

    /**
     */
    async battlePassTakeRewardCreate(requestParameters: EventsApiBattlePassTakeRewardCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RewardInfo> {
        const response = await this.battlePassTakeRewardCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async battlePassUserRewardInfoRetrieveRaw(requestParameters: EventsApiBattlePassUserRewardInfoRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RewardInfo>> {
        if (requestParameters['levelId'] == null) {
            throw new runtime.RequiredError(
                'levelId',
                'Required parameter "levelId" was null or undefined when calling battlePassUserRewardInfoRetrieve().'
            );
        }

        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling battlePassUserRewardInfoRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/battle-pass/user-reward-info/{level_id}/`.replace(`{${"level_id"}}`, encodeURIComponent(String(requestParameters['levelId']))).replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RewardInfoFromJSON(jsonValue));
    }

    /**
     */
    async battlePassUserRewardInfoRetrieve(requestParameters: EventsApiBattlePassUserRewardInfoRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RewardInfo> {
        const response = await this.battlePassUserRewardInfoRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bossBattleAttackActionsListRaw(requestParameters: EventsApiBossBattleAttackActionsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BossBattleAttackAction>>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling bossBattleAttackActionsList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/boss-battle/attack-actions/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BossBattleAttackActionFromJSON));
    }

    /**
     */
    async bossBattleAttackActionsList(requestParameters: EventsApiBossBattleAttackActionsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BossBattleAttackAction>> {
        const response = await this.bossBattleAttackActionsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bossBattleAttackCreateRaw(requestParameters: EventsApiBossBattleAttackCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling bossBattleAttackCreate().'
            );
        }

        if (requestParameters['bossBattleAttackRequest'] == null) {
            throw new runtime.RequiredError(
                'bossBattleAttackRequest',
                'Required parameter "bossBattleAttackRequest" was null or undefined when calling bossBattleAttackCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/boss-battle/attack/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BossBattleAttackRequestToJSON(requestParameters['bossBattleAttackRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async bossBattleAttackCreate(requestParameters: EventsApiBossBattleAttackCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bossBattleAttackCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async bossBattleProfileRetrieveRaw(requestParameters: EventsApiBossBattleProfileRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BossBattleProfile>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling bossBattleProfileRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/boss-battle/profile/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BossBattleProfileFromJSON(jsonValue));
    }

    /**
     */
    async bossBattleProfileRetrieve(requestParameters: EventsApiBossBattleProfileRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BossBattleProfile> {
        const response = await this.bossBattleProfileRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bossBattleRetrieveRaw(requestParameters: EventsApiBossBattleRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BossBattleEvent>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling bossBattleRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/boss-battle/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BossBattleEventFromJSON(jsonValue));
    }

    /**
     */
    async bossBattleRetrieve(requestParameters: EventsApiBossBattleRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BossBattleEvent> {
        const response = await this.bossBattleRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bossBattleRewardsListRaw(requestParameters: EventsApiBossBattleRewardsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RewardRange>>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling bossBattleRewardsList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/boss-battle/rewards/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RewardRangeFromJSON));
    }

    /**
     */
    async bossBattleRewardsList(requestParameters: EventsApiBossBattleRewardsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RewardRange>> {
        const response = await this.bossBattleRewardsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async bossBattleTopRetrieveRaw(requestParameters: EventsApiBossBattleTopRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BossBattleTopResponse>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling bossBattleTopRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/boss-battle/top/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BossBattleTopResponseFromJSON(jsonValue));
    }

    /**
     */
    async bossBattleTopRetrieve(requestParameters: EventsApiBossBattleTopRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BossBattleTopResponse> {
        const response = await this.bossBattleTopRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exchangerExchangeCreateRaw(requestParameters: EventsApiExchangerExchangeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubjectInventory>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling exchangerExchangeCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/exchanger/exchange/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubjectInventoryRequestToJSON(requestParameters['subjectInventoryRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubjectInventoryFromJSON(jsonValue));
    }

    /**
     */
    async exchangerExchangeCreate(requestParameters: EventsApiExchangerExchangeCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubjectInventory> {
        const response = await this.exchangerExchangeCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exchangerProfileRetrieveRaw(requestParameters: EventsApiExchangerProfileRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExchangerProfile>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling exchangerProfileRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/exchanger/profile/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExchangerProfileFromJSON(jsonValue));
    }

    /**
     */
    async exchangerProfileRetrieve(requestParameters: EventsApiExchangerProfileRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExchangerProfile> {
        const response = await this.exchangerProfileRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exchangerRetrieveRaw(requestParameters: EventsApiExchangerRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExchangerSettings>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling exchangerRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/exchanger/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExchangerSettingsFromJSON(jsonValue));
    }

    /**
     */
    async exchangerRetrieve(requestParameters: EventsApiExchangerRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExchangerSettings> {
        const response = await this.exchangerRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exchangerTopListRaw(requestParameters: EventsApiExchangerTopListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ExchangerTopUser>>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling exchangerTopList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/exchanger/top/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExchangerTopUserFromJSON));
    }

    /**
     */
    async exchangerTopList(requestParameters: EventsApiExchangerTopListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ExchangerTopUser>> {
        const response = await this.exchangerTopListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exchangerTopRewardsListRaw(requestParameters: EventsApiExchangerTopRewardsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ExchangerRewardRange>>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling exchangerTopRewardsList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/exchanger/top_rewards/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExchangerRewardRangeFromJSON));
    }

    /**
     */
    async exchangerTopRewardsList(requestParameters: EventsApiExchangerTopRewardsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ExchangerRewardRange>> {
        const response = await this.exchangerTopRewardsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async exchangerTopUserRetrieveRaw(requestParameters: EventsApiExchangerTopUserRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExchangerTopUser>> {
        if (requestParameters['slug'] == null) {
            throw new runtime.RequiredError(
                'slug',
                'Required parameter "slug" was null or undefined when calling exchangerTopUserRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("tokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/events/{slug}/exchanger/top/user/`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters['slug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExchangerTopUserFromJSON(jsonValue));
    }

    /**
     */
    async exchangerTopUserRetrieve(requestParameters: EventsApiExchangerTopUserRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExchangerTopUser> {
        const response = await this.exchangerTopUserRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
