// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ArtifactRewardTyped,
    instanceOfArtifactRewardTyped,
    ArtifactRewardTypedFromJSON,
    ArtifactRewardTypedFromJSONTyped,
    ArtifactRewardTypedToJSON,
} from './ArtifactRewardTyped';
import {
    AvatarBorderTyped,
    instanceOfAvatarBorderTyped,
    AvatarBorderTypedFromJSON,
    AvatarBorderTypedFromJSONTyped,
    AvatarBorderTypedToJSON,
} from './AvatarBorderTyped';
import {
    InventoryTyped,
    instanceOfInventoryTyped,
    InventoryTypedFromJSON,
    InventoryTypedFromJSONTyped,
    InventoryTypedToJSON,
} from './InventoryTyped';
import {
    NicknameDecorationRewardTyped,
    instanceOfNicknameDecorationRewardTyped,
    NicknameDecorationRewardTypedFromJSON,
    NicknameDecorationRewardTypedFromJSONTyped,
    NicknameDecorationRewardTypedToJSON,
} from './NicknameDecorationRewardTyped';
import {
    SimpleCaseTyped,
    instanceOfSimpleCaseTyped,
    SimpleCaseTypedFromJSON,
    SimpleCaseTypedFromJSONTyped,
    SimpleCaseTypedToJSON,
} from './SimpleCaseTyped';
import {
    SimpleResponseTyped,
    instanceOfSimpleResponseTyped,
    SimpleResponseTypedFromJSON,
    SimpleResponseTypedFromJSONTyped,
    SimpleResponseTypedToJSON,
} from './SimpleResponseTyped';

/**
 * @type Rewards
 * 
 * @export
 */
export type Rewards = { resourcetype: 'ArtifactReward' } & ArtifactRewardTyped | { resourcetype: 'AvatarBorderReward' } & AvatarBorderTyped | { resourcetype: 'FreeCaseOpenReward' } & SimpleCaseTyped | { resourcetype: 'GoldReward' } & SimpleResponseTyped | { resourcetype: 'NicknameDecorationReward' } & NicknameDecorationRewardTyped | { resourcetype: 'PromoCodePercentReward' } & SimpleResponseTyped | { resourcetype: 'SkinReward' } & InventoryTyped | { resourcetype: 'SockWithSubjectReward' } & InventoryTyped | { resourcetype: 'StickerReward' } & InventoryTyped | { resourcetype: 'SubjectWithChanceReward' } & InventoryTyped;

export function RewardsFromJSON(json: any): Rewards {
    return RewardsFromJSONTyped(json, false);
}

export function RewardsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Rewards {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['resourcetype']) {
        case 'ArtifactReward':
            return {...ArtifactRewardTypedFromJSONTyped(json, true), resourcetype: 'ArtifactReward'};
        case 'AvatarBorderReward':
            return {...AvatarBorderTypedFromJSONTyped(json, true), resourcetype: 'AvatarBorderReward'};
        case 'FreeCaseOpenReward':
            return {...SimpleCaseTypedFromJSONTyped(json, true), resourcetype: 'FreeCaseOpenReward'};
        case 'GoldReward':
            return {...SimpleResponseTypedFromJSONTyped(json, true), resourcetype: 'GoldReward'};
        case 'NicknameDecorationReward':
            return {...NicknameDecorationRewardTypedFromJSONTyped(json, true), resourcetype: 'NicknameDecorationReward'};
        case 'PromoCodePercentReward':
            return {...SimpleResponseTypedFromJSONTyped(json, true), resourcetype: 'PromoCodePercentReward'};
        case 'SkinReward':
            return {...InventoryTypedFromJSONTyped(json, true), resourcetype: 'SkinReward'};
        case 'SockWithSubjectReward':
            return {...InventoryTypedFromJSONTyped(json, true), resourcetype: 'SockWithSubjectReward'};
        case 'StickerReward':
            return {...InventoryTypedFromJSONTyped(json, true), resourcetype: 'StickerReward'};
        case 'SubjectWithChanceReward':
            return {...InventoryTypedFromJSONTyped(json, true), resourcetype: 'SubjectWithChanceReward'};
        default:
            throw new Error(`No variant of Rewards exists with 'resourcetype=${json['resourcetype']}'`);
    }
}

export function RewardsToJSON(value?: Rewards | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['resourcetype']) {
        case 'ArtifactReward':
            return ArtifactRewardTypedToJSON(value);
        case 'AvatarBorderReward':
            return AvatarBorderTypedToJSON(value);
        case 'FreeCaseOpenReward':
            return SimpleCaseTypedToJSON(value);
        case 'GoldReward':
            return SimpleResponseTypedToJSON(value);
        case 'NicknameDecorationReward':
            return NicknameDecorationRewardTypedToJSON(value);
        case 'PromoCodePercentReward':
            return SimpleResponseTypedToJSON(value);
        case 'SkinReward':
            return InventoryTypedToJSON(value);
        case 'SockWithSubjectReward':
            return InventoryTypedToJSON(value);
        case 'StickerReward':
            return InventoryTypedToJSON(value);
        case 'SubjectWithChanceReward':
            return InventoryTypedToJSON(value);
        default:
            throw new Error(`No variant of Rewards exists with 'resourcetype=${value['resourcetype']}'`);
    }

}

